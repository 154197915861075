var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "page1",
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "text-align": "right",
            "margin-left": "8.5%",
            margin: "20px 0",
          },
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "88px" },
              attrs: { type: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "formInline",
          attrs: {
            "label-position": "right",
            "label-width": "110px",
            model: _vm.formInline,
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("searchModule.Merchant_Name"),
                prop: "operationId",
              },
            },
            [
              _c(
                "el-select",
                {
                  attrs: { disabled: "", filterable: "", size: "15" },
                  model: {
                    value: _vm.formInline.operationId,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formInline,
                        "operationId",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "formInline.operationId",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "请选择", value: "" } }),
                  _vm._l(_vm.roleList, function (value) {
                    return _c("el-option", {
                      key: value.operationId,
                      attrs: {
                        label: value.operationName,
                        value: value.operationId,
                      },
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c("div", { staticClass: "content bgFFF" }, [
            _c("div", { staticClass: "title" }, [_vm._v("入场小票")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btn" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.onShowConfigure(1)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.showList.includes(1) ? "折叠" : "展开"))]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.onDiaShow(1, "entryTicket")
                      },
                    },
                  },
                  [_vm._v("预览")]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showList.includes(1),
                    expression: "!showList.includes(1)",
                  },
                ],
                staticClass: "subtitle",
              },
              [_vm._v("小票标题：" + _vm._s(_vm.formInline.entryTicket.title))]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showList.includes(1),
                    expression: "showList.includes(1)",
                  },
                ],
                staticClass: "box",
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "小票标题", prop: "entryTicket.title" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "221px" },
                      attrs: {
                        disabled: "",
                        maxlength: 30,
                        placeholder: "请输入小票标题",
                      },
                      model: {
                        value: _vm.formInline.entryTicket.title,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline.entryTicket,
                            "title",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInline.entryTicket.title",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: { label: "停车信息", prop: "entryTicket.parkList" },
                  },
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.formInline.entryTicket.parkList,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formInline.entryTicket,
                              "parkList",
                              $$v
                            )
                          },
                          expression: "formInline.entryTicket.parkList",
                        },
                      },
                      [
                        _vm.formInline.entryTicket.plateNumber
                          ? _c("el-checkbox", { attrs: { label: 1 } }, [
                              _vm._v("车牌号码"),
                            ])
                          : _vm._e(),
                        _vm.formInline.entryTicket.plateColor
                          ? _c("el-checkbox", { attrs: { label: 2 } }, [
                              _vm._v("车牌颜色"),
                            ])
                          : _vm._e(),
                        _vm.formInline.entryTicket.parkName
                          ? _c("el-checkbox", { attrs: { label: 3 } }, [
                              _vm._v("停放路段"),
                            ])
                          : _vm._e(),
                        _vm.formInline.entryTicket.berthCode
                          ? _c("el-checkbox", { attrs: { label: 4 } }, [
                              _vm._v("停放泊位"),
                            ])
                          : _vm._e(),
                        _vm.formInline.entryTicket.entryTime
                          ? _c("el-checkbox", { attrs: { label: 5 } }, [
                              _vm._v("入场时间"),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.formInline.entryTicket.entryOptList.length
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "运营信息",
                          prop: "entryTicket.entryOptList",
                        },
                      },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.formInline.entryTicket.entryOptList,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formInline.entryTicket,
                                  "entryOptList",
                                  $$v
                                )
                              },
                              expression: "formInline.entryTicket.entryOptList",
                            },
                          },
                          [
                            _vm.formInline.entryTicket.operationName
                              ? _c("el-checkbox", { attrs: { label: 1 } }, [
                                  _vm._v("运营单位"),
                                ])
                              : _vm._e(),
                            _vm.formInline.entryTicket.pdaManagerCode
                              ? _c("el-checkbox", { attrs: { label: 2 } }, [
                                  _vm._v("收费员编号"),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.formInline.entryTicket.printTime
                  ? _c(
                      "el-form-item",
                      {
                        attrs: { label: "其它", prop: "entryTicket.printTime" },
                      },
                      [
                        _c(
                          "el-checkbox",
                          {
                            attrs: {
                              disabled: "",
                              "false-label": 0,
                              "true-label": 1,
                            },
                            model: {
                              value: _vm.formInline.entryTicket.printTime,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formInline.entryTicket,
                                  "printTime",
                                  $$v
                                )
                              },
                              expression: "formInline.entryTicket.printTime",
                            },
                          },
                          [_vm._v("打印时间")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.formInline.entryTicket.codeList.length
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "二维码",
                          prop: "entryTicket.codeList",
                        },
                      },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.formInline.entryTicket.codeList,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formInline.entryTicket,
                                  "codeList",
                                  $$v
                                )
                              },
                              expression: "formInline.entryTicket.codeList",
                            },
                          },
                          [
                            _vm.formInline.entryTicket.innerPayUrl
                              ? _c("el-checkbox", { attrs: { label: 1 } }, [
                                  _vm._v("场中支付二维码（扫码打开订单详情）"),
                                ])
                              : _vm._e(),
                            _vm.formInline.entryTicket.debtPayUrl
                              ? _c("el-checkbox", { attrs: { label: 2 } }, [
                                  _vm._v("欠费追缴二维码（扫码打开欠费列表）"),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "扫码方式",
                      prop: "entryTicket.scanCodeInfo",
                    },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "400px" },
                      attrs: {
                        type: "textarea",
                        disabled: "",
                        maxlength: 60,
                        rows: 6,
                        placeholder: "请输入扫码方式",
                      },
                      model: {
                        value: _vm.formInline.entryTicket.scanCodeInfo,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline.entryTicket,
                            "scanCodeInfo",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInline.entryTicket.scanCodeInfo",
                      },
                    }),
                  ],
                  1
                ),
                _vm.formInline.entryTicket.remark
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "温馨提示",
                          prop: "entryTicket.remark",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "400px" },
                          attrs: {
                            type: "textarea",
                            disabled: "",
                            maxlength: 300,
                            rows: 6,
                            placeholder: "请输入温馨提示",
                          },
                          model: {
                            value: _vm.formInline.entryTicket.remark,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formInline.entryTicket,
                                "remark",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInline.entryTicket.remark",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "content bgFFF" }, [
            _c("div", { staticClass: "title" }, [_vm._v("预付费小票")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btn" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.onShowConfigure(2)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.showList.includes(2) ? "折叠" : "展开"))]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.onDiaShow(2, "preTicket")
                      },
                    },
                  },
                  [_vm._v("预览")]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showList.includes(2),
                    expression: "!showList.includes(2)",
                  },
                ],
                staticClass: "subtitle",
              },
              [_vm._v("小票标题：" + _vm._s(_vm.formInline.preTicket.title))]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showList.includes(2),
                    expression: "showList.includes(2)",
                  },
                ],
                staticClass: "box",
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "小票标题", prop: "preTicket.title" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "221px" },
                      attrs: {
                        disabled: "",
                        maxlength: 30,
                        placeholder: "请输入小票标题",
                      },
                      model: {
                        value: _vm.formInline.preTicket.title,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline.preTicket,
                            "title",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInline.preTicket.title",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "停车信息", prop: "preTicket.parkList" } },
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.formInline.preTicket.parkList,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline.preTicket, "parkList", $$v)
                          },
                          expression: "formInline.preTicket.parkList",
                        },
                      },
                      [
                        _vm.formInline.preTicket.plateNumber
                          ? _c("el-checkbox", { attrs: { label: 1 } }, [
                              _vm._v("车牌号码"),
                            ])
                          : _vm._e(),
                        _vm.formInline.preTicket.plateColor
                          ? _c("el-checkbox", { attrs: { label: 2 } }, [
                              _vm._v("车牌颜色"),
                            ])
                          : _vm._e(),
                        _vm.formInline.preTicket.parkName
                          ? _c("el-checkbox", { attrs: { label: 3 } }, [
                              _vm._v("停放路段"),
                            ])
                          : _vm._e(),
                        _vm.formInline.preTicket.berthCode
                          ? _c("el-checkbox", { attrs: { label: 4 } }, [
                              _vm._v("停放泊位"),
                            ])
                          : _vm._e(),
                        _vm.formInline.preTicket.entryTime
                          ? _c("el-checkbox", { attrs: { label: 5 } }, [
                              _vm._v("入场时间"),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "支付信息", prop: "preTicket.payList" } },
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.formInline.preTicket.payList,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline.preTicket, "payList", $$v)
                          },
                          expression: "formInline.preTicket.payList",
                        },
                      },
                      [
                        _vm.formInline.preTicket.preMoney
                          ? _c("el-checkbox", { attrs: { label: 1 } }, [
                              _vm._v("预付金额"),
                            ])
                          : _vm._e(),
                        _vm.formInline.preTicket.payTime
                          ? _c("el-checkbox", { attrs: { label: 2 } }, [
                              _vm._v("支付时间"),
                            ])
                          : _vm._e(),
                        _vm.formInline.preTicket.payType
                          ? _c("el-checkbox", { attrs: { label: 3 } }, [
                              _vm._v("支付方式"),
                            ])
                          : _vm._e(),
                        _vm.formInline.preTicket.tradeNo
                          ? _c("el-checkbox", { attrs: { label: 4 } }, [
                              _vm._v("订单编号"),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.formInline.preTicket.entryOptList.length
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "运营信息",
                          prop: "preTicket.entryOptList",
                        },
                      },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.formInline.preTicket.entryOptList,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formInline.preTicket,
                                  "entryOptList",
                                  $$v
                                )
                              },
                              expression: "formInline.preTicket.entryOptList",
                            },
                          },
                          [
                            _vm.formInline.preTicket.operationName
                              ? _c("el-checkbox", { attrs: { label: 1 } }, [
                                  _vm._v("运营单位"),
                                ])
                              : _vm._e(),
                            _vm.formInline.preTicket.pdaManagerCode
                              ? _c("el-checkbox", { attrs: { label: 2 } }, [
                                  _vm._v("收费员编号"),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.formInline.preTicket.printTime
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "其它", prop: "preTicket.printTime" } },
                      [
                        _c(
                          "el-checkbox",
                          {
                            attrs: {
                              disabled: "",
                              "false-label": 0,
                              "true-label": 1,
                            },
                            model: {
                              value: _vm.formInline.preTicket.printTime,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formInline.preTicket,
                                  "printTime",
                                  $$v
                                )
                              },
                              expression: "formInline.preTicket.printTime",
                            },
                          },
                          [_vm._v("打印时间")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  { attrs: { label: "二维码", prop: "preTicket.codeList" } },
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.formInline.preTicket.codeList,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline.preTicket, "codeList", $$v)
                          },
                          expression: "formInline.preTicket.codeList",
                        },
                      },
                      [
                        _vm.formInline.preTicket.innerPayUrl
                          ? _c("el-checkbox", { attrs: { label: 1 } }, [
                              _vm._v("场中支付二维码（扫码打开订单详情）"),
                            ])
                          : _vm._e(),
                        _vm.formInline.preTicket.debtPayUrl
                          ? _c("el-checkbox", { attrs: { label: 2 } }, [
                              _vm._v("欠费追缴二维码（扫码打开欠费列表）"),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "扫码方式",
                      prop: "preTicket.scanCodeInfo",
                    },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "400px" },
                      attrs: {
                        disabled: "",
                        type: "textarea",
                        maxlength: 60,
                        rows: 6,
                        placeholder: "请输入扫码方式",
                      },
                      model: {
                        value: _vm.formInline.preTicket.scanCodeInfo,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline.preTicket,
                            "scanCodeInfo",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInline.preTicket.scanCodeInfo",
                      },
                    }),
                  ],
                  1
                ),
                _vm.formInline.preTicket.remark
                  ? _c(
                      "el-form-item",
                      {
                        attrs: { label: "温馨提示", prop: "preTicket.remark" },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "400px" },
                          attrs: {
                            disabled: "",
                            type: "textarea",
                            maxlength: 300,
                            rows: 6,
                            placeholder: "请输入温馨提示",
                          },
                          model: {
                            value: _vm.formInline.preTicket.remark,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formInline.preTicket,
                                "remark",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInline.preTicket.remark",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "content bgFFF" }, [
            _c("div", { staticClass: "title" }, [_vm._v("出场小票")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btn" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.onShowConfigure(3)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.showList.includes(3) ? "折叠" : "展开"))]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.onDiaShow(3, "exitTicket")
                      },
                    },
                  },
                  [_vm._v("预览")]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showList.includes(3),
                    expression: "!showList.includes(3)",
                  },
                ],
                staticClass: "subtitle",
              },
              [_vm._v("小票标题：" + _vm._s(_vm.formInline.exitTicket.title))]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showList.includes(3),
                    expression: "showList.includes(3)",
                  },
                ],
                staticClass: "box",
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "小票标题", prop: "exitTicket.title" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "221px" },
                      attrs: {
                        disabled: "",
                        maxlength: 30,
                        placeholder: "请输入小票标题",
                      },
                      model: {
                        value: _vm.formInline.exitTicket.title,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline.exitTicket,
                            "title",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInline.exitTicket.title",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "停车信息", prop: "exitTicket.parkList" } },
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.formInline.exitTicket.parkList,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline.exitTicket, "parkList", $$v)
                          },
                          expression: "formInline.exitTicket.parkList",
                        },
                      },
                      [
                        _vm.formInline.exitTicket.plateNumber
                          ? _c("el-checkbox", { attrs: { label: 1 } }, [
                              _vm._v("车牌号码"),
                            ])
                          : _vm._e(),
                        _vm.formInline.exitTicket.plateColor
                          ? _c("el-checkbox", { attrs: { label: 2 } }, [
                              _vm._v("车牌颜色"),
                            ])
                          : _vm._e(),
                        _vm.formInline.exitTicket.parkName
                          ? _c("el-checkbox", { attrs: { label: 3 } }, [
                              _vm._v("停放路段"),
                            ])
                          : _vm._e(),
                        _vm.formInline.exitTicket.berthCode
                          ? _c("el-checkbox", { attrs: { label: 4 } }, [
                              _vm._v("停放泊位"),
                            ])
                          : _vm._e(),
                        _vm.formInline.exitTicket.entryTime
                          ? _c("el-checkbox", { attrs: { label: 5 } }, [
                              _vm._v("入场时间"),
                            ])
                          : _vm._e(),
                        _vm.formInline.exitTicket.exitTime
                          ? _c("el-checkbox", { attrs: { label: 6 } }, [
                              _vm._v("出场时间"),
                            ])
                          : _vm._e(),
                        _vm.formInline.exitTicket.duration
                          ? _c("el-checkbox", { attrs: { label: 7 } }, [
                              _vm._v("停车时长"),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "支付信息", prop: "exitTicket.payList" } },
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.formInline.exitTicket.payList,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline.exitTicket, "payList", $$v)
                          },
                          expression: "formInline.exitTicket.payList",
                        },
                      },
                      [
                        _vm.formInline.exitTicket.totalMoney
                          ? _c("el-checkbox", { attrs: { label: 1 } }, [
                              _vm._v("订单总额"),
                            ])
                          : _vm._e(),
                        _vm.formInline.exitTicket.payMoney
                          ? _c("el-checkbox", { attrs: { label: 2 } }, [
                              _vm._v("已付金额"),
                            ])
                          : _vm._e(),
                        _vm.formInline.exitTicket.parkDiscountMoney
                          ? _c("el-checkbox", { attrs: { label: 3 } }, [
                              _vm._v("停车场折扣"),
                            ])
                          : _vm._e(),
                        _vm.formInline.exitTicket.parkCardMoney
                          ? _c("el-checkbox", { attrs: { label: 4 } }, [
                              _vm._v("停车卡抵扣"),
                            ])
                          : _vm._e(),
                        _vm.formInline.exitTicket.couponMoney
                          ? _c("el-checkbox", { attrs: { label: 5 } }, [
                              _vm._v("优惠券"),
                            ])
                          : _vm._e(),
                        _vm.formInline.exitTicket.refundMoney
                          ? _c("el-checkbox", { attrs: { label: 6 } }, [
                              _vm._v("退款金额"),
                            ])
                          : _vm._e(),
                        _vm.formInline.exitTicket.money
                          ? _c("el-checkbox", { attrs: { label: 7 } }, [
                              _vm._v("还需支付"),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.formInline.exitTicket.entryOptList.length
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "运营信息",
                          prop: "exitTicket.entryOptList",
                        },
                      },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.formInline.exitTicket.entryOptList,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formInline.exitTicket,
                                  "entryOptList",
                                  $$v
                                )
                              },
                              expression: "formInline.exitTicket.entryOptList",
                            },
                          },
                          [
                            _vm.formInline.exitTicket.operationName
                              ? _c("el-checkbox", { attrs: { label: 1 } }, [
                                  _vm._v("运营单位"),
                                ])
                              : _vm._e(),
                            _vm.formInline.exitTicket.pdaManagerCode
                              ? _c("el-checkbox", { attrs: { label: 2 } }, [
                                  _vm._v("收费员编号"),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.formInline.exitTicket.printTime
                  ? _c(
                      "el-form-item",
                      {
                        attrs: { label: "其它", prop: "exitTicket.printTime" },
                      },
                      [
                        _c(
                          "el-checkbox",
                          {
                            attrs: {
                              disabled: "",
                              "false-label": 0,
                              "true-label": 1,
                            },
                            model: {
                              value: _vm.formInline.exitTicket.printTime,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formInline.exitTicket,
                                  "printTime",
                                  $$v
                                )
                              },
                              expression: "formInline.exitTicket.printTime",
                            },
                          },
                          [_vm._v("打印时间")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  { attrs: { label: "二维码", prop: "exitTicket.codeList" } },
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.formInline.exitTicket.codeList,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline.exitTicket, "codeList", $$v)
                          },
                          expression: "formInline.exitTicket.codeList",
                        },
                      },
                      [
                        _c("el-checkbox", { attrs: { label: 1 } }, [
                          _vm._v(
                            "查看订单二维码（扫码打开订单详情或欠费列表）"
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "扫码方式",
                      prop: "exitTicket.scanCodeInfo",
                    },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "400px" },
                      attrs: {
                        disabled: "",
                        type: "textarea",
                        maxlength: 60,
                        rows: 6,
                        placeholder: "请输入扫码方式",
                      },
                      model: {
                        value: _vm.formInline.exitTicket.scanCodeInfo,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline.exitTicket,
                            "scanCodeInfo",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInline.exitTicket.scanCodeInfo",
                      },
                    }),
                  ],
                  1
                ),
                _vm.formInline.exitTicket.remark
                  ? _c(
                      "el-form-item",
                      {
                        attrs: { label: "温馨提示", prop: "exitTicket.remark" },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "400px" },
                          attrs: {
                            disabled: "",
                            type: "textarea",
                            maxlength: 300,
                            rows: 6,
                            placeholder: "请输入温馨提示",
                          },
                          model: {
                            value: _vm.formInline.exitTicket.remark,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formInline.exitTicket,
                                "remark",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInline.exitTicket.remark",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "content bgFFF" }, [
            _c("div", { staticClass: "title" }, [_vm._v("缴费小票")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btn" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.onShowConfigure(4)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.showList.includes(4) ? "折叠" : "展开"))]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.onDiaShow(4, "debtTicket")
                      },
                    },
                  },
                  [_vm._v("预览")]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showList.includes(4),
                    expression: "!showList.includes(4)",
                  },
                ],
                staticClass: "subtitle",
              },
              [_vm._v("小票标题：" + _vm._s(_vm.formInline.debtTicket.title))]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showList.includes(4),
                    expression: "showList.includes(4)",
                  },
                ],
                staticClass: "box",
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "小票标题", prop: "debtTicket.title" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "221px" },
                      attrs: {
                        disabled: "",
                        maxlength: 30,
                        placeholder: "请输入小票标题",
                      },
                      model: {
                        value: _vm.formInline.debtTicket.title,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline.debtTicket,
                            "title",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInline.debtTicket.title",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "停车信息", prop: "debtTicket.parkList" } },
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.formInline.debtTicket.parkList,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline.debtTicket, "parkList", $$v)
                          },
                          expression: "formInline.debtTicket.parkList",
                        },
                      },
                      [
                        _vm.formInline.debtTicket.plateNumber
                          ? _c("el-checkbox", { attrs: { label: 1 } }, [
                              _vm._v("车牌号码"),
                            ])
                          : _vm._e(),
                        _vm.formInline.debtTicket.plateColor
                          ? _c("el-checkbox", { attrs: { label: 2 } }, [
                              _vm._v("车牌颜色"),
                            ])
                          : _vm._e(),
                        _vm.formInline.debtTicket.parkName
                          ? _c("el-checkbox", { attrs: { label: 3 } }, [
                              _vm._v("停放路段"),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "支付信息", prop: "debtTicket.payList" } },
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.formInline.debtTicket.payList,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline.debtTicket, "payList", $$v)
                          },
                          expression: "formInline.debtTicket.payList",
                        },
                      },
                      [
                        _vm.formInline.debtTicket.count
                          ? _c("el-checkbox", { attrs: { label: 1 } }, [
                              _vm._v("缴纳单数"),
                            ])
                          : _vm._e(),
                        _vm.formInline.debtTicket.shouldMoney
                          ? _c("el-checkbox", { attrs: { label: 2 } }, [
                              _vm._v("应付金额"),
                            ])
                          : _vm._e(),
                        _vm.formInline.debtTicket.money
                          ? _c("el-checkbox", { attrs: { label: 3 } }, [
                              _vm._v("实付金额"),
                            ])
                          : _vm._e(),
                        _vm.formInline.debtTicket.payType
                          ? _c("el-checkbox", { attrs: { label: 4 } }, [
                              _vm._v("支付方式"),
                            ])
                          : _vm._e(),
                        _vm.formInline.debtTicket.payTime
                          ? _c("el-checkbox", { attrs: { label: 5 } }, [
                              _vm._v("支付时间"),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.formInline.debtTicket.entryOptList.length
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "运营信息",
                          prop: "debtTicket.entryOptList",
                        },
                      },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.formInline.debtTicket.entryOptList,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formInline.debtTicket,
                                  "entryOptList",
                                  $$v
                                )
                              },
                              expression: "formInline.debtTicket.entryOptList",
                            },
                          },
                          [
                            _vm.formInline.debtTicket.operationName
                              ? _c("el-checkbox", { attrs: { label: 1 } }, [
                                  _vm._v("运营单位"),
                                ])
                              : _vm._e(),
                            _vm.formInline.debtTicket.pdaManagerCode
                              ? _c("el-checkbox", { attrs: { label: 2 } }, [
                                  _vm._v("收费员编号"),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.formInline.debtTicket.printTime
                  ? _c(
                      "el-form-item",
                      {
                        attrs: { label: "其它", prop: "debtTicket.printTime" },
                      },
                      [
                        _c(
                          "el-checkbox",
                          {
                            attrs: {
                              disabled: "",
                              "false-label": 0,
                              "true-label": 1,
                            },
                            model: {
                              value: _vm.formInline.debtTicket.printTime,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formInline.debtTicket,
                                  "printTime",
                                  $$v
                                )
                              },
                              expression: "formInline.debtTicket.printTime",
                            },
                          },
                          [_vm._v("打印时间")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  { attrs: { label: "二维码", prop: "debtTicket.codeList" } },
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.formInline.debtTicket.codeList,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline.debtTicket, "codeList", $$v)
                          },
                          expression: "formInline.debtTicket.codeList",
                        },
                      },
                      [
                        _c("el-checkbox", { attrs: { label: 1 } }, [
                          _vm._v(
                            "查看订单二维码（扫码打开订单详情或欠费列表）"
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "扫码方式",
                      prop: "debtTicket.scanCodeInfo",
                    },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "400px" },
                      attrs: {
                        disabled: "",
                        type: "textarea",
                        maxlength: 60,
                        rows: 6,
                        placeholder: "请输入扫码方式",
                      },
                      model: {
                        value: _vm.formInline.debtTicket.scanCodeInfo,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline.debtTicket,
                            "scanCodeInfo",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInline.debtTicket.scanCodeInfo",
                      },
                    }),
                  ],
                  1
                ),
                _vm.formInline.debtTicket.remark
                  ? _c(
                      "el-form-item",
                      {
                        attrs: { label: "温馨提示", prop: "debtTicket.remark" },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "400px" },
                          attrs: {
                            disabled: "",
                            type: "textarea",
                            maxlength: 300,
                            rows: 6,
                            placeholder: "请输入温馨提示",
                          },
                          model: {
                            value: _vm.formInline.debtTicket.remark,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formInline.debtTicket,
                                "remark",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInline.debtTicket.remark",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            "text-align": "center",
            "margin-left": "8.5%",
            margin: "20px 0",
          },
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "88px" },
              attrs: { type: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, width: "50%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.showIndex === 1
            ? _c("EntryTicket", { attrs: { ticketObj: _vm.previewObj } })
            : _vm._e(),
          _vm.showIndex === 2
            ? _c("PreTicket", { attrs: { ticketObj: _vm.previewObj } })
            : _vm._e(),
          _vm.showIndex === 3
            ? _c("ExitTicket", { attrs: { ticketObj: _vm.previewObj } })
            : _vm._e(),
          _vm.showIndex === 4
            ? _c("DebtTicket", { attrs: { ticketObj: _vm.previewObj } })
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }